<template>
    <v-card elevation="0">
        <v-card-actions >
            <v-card-title>{{$t('Are you sure you want to delete Solution Type')}} {{name}}?</v-card-title>
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'DealsSettings', query: { tab: 'st'} }" color="green" rounded>{{$t('Cancel')}}</v-btn>

            <v-btn
                color="primary"
                dark
                rounded
                @click="deleteItemConfirm"
            >
                {{$t('Yes')}}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        data(){
            return{
                id:"",
                name:""
            }
        },
        created: function()
        {
            if(this.$route.query.id){
                this.id = this.$route.query.id
                this.name = this.$route.query.name
            }
        },
        methods: {

            deleteItemConfirm () {
                if(this.id !=""){
                    let uri = this.$urlPrefix + '/SolutionType/Delete?id='+this.id;
                    this.axios.delete(uri).then((response) => {
                        if(response.data == true){
                            this.$router.push({name: 'DealsSettings', query: { tab: 'st'}});
                        }
                    });
                }
            },
        }
    }
</script>
